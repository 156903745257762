@media (max-width:500px) {
  
    

    .cardProperty {
        align-items: center;
        width: 100%;
        border: 1px solid black;
        margin-left: 10px;

        margin-top: 15px;

        border-radius: 5px;
      }


}

.mapbutton{
    display: none!important;
}

.hide{
    display: none;
    
}
.show{
    display: block;
}
.show2{
    display: block!important;
}
@media(max-width:591px){
  
}
@media (max-width:760px) {
   .maptop{
    display: none!important;
   }
  
    .mapbutton{
        display: block!important;
        position: absolute;
        top: 20%;
        left: 40%;
        color: wheat;
        background-color: black;
        
    } 
    .ListButton{
        display: block!important;
         z-index: 11111;
        position: absolute;
        top: 20%;
        left: 40%;
        color: wheat;
        background-color: black;
      
    }
    


      
      #kanjarmap{
        position: absolute;
        bottom: -28%;
      }

}


.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:150px;
	background-color:#E83D3D;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float{
	margin-top:22px;
}