@tailwind base;
@tailwind components;
@tailwind utilities;


.leaflet-container {
  width: 100vw;
  height: 100vh;
}

.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 1000px;
  height: 600px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

/* Map Style */
.mainContainer {
  /* background-color: #061539; */
  
  height: 100vh;
  width: 100vw;
}
.informationProperty {
  margin: 10px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid white;
}
.informationProperty > p {
  color: white;
}

.cardProperty {
  align-items: center;
  width: 48%;
  /* border: 1px solid black; */
  margin-left: 10px;
  /* margin-right: 10px; */
  margin-top: 15px;
  /* padding: 10px; */
  border-radius: 5px;
  /* display: flex; */
}

.imageProperty {
  width: 100%;
  height: 210px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  
}

.headerContainer {
  height: 10vh;
}
.mapContainer > .row {
  margin-left: 0;
  margin-right: 0;
}

.searchContainer {
  /* background-color: #061539; */
  height: 100vh;
  /* width: 100vw; */
  padding-bottom: 10px;
  overflow: scroll;
  overflow-x: hidden;
}
.property-description {
  font-size: 12px;
}
.text-icon {
  display: flex;
}

.searchContainer > h4 {
  padding: 20px;
  color: white;
}

.actualMap {
  /* width: 100%;
    height: 100%; */
}
/* Map Style End */

.courser:hover {
  cursor: pointer;
}

.navBarLogo {
  width: 200px;
  height: auto;
  padding: 10px;
}
.navBar {
  padding: 12px;
}
.inputSearch {
}
.map {
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.Cmouse {
  font-size: 30px;
  cursor: pointer;
}
.drawer-nav>ul{
  list-style-type:none;
}
.drawer-nav>ul>li{
  font-size: larger;
  color: black;
  font-weight: 700;
  margin: 10px;
}


.leaflet-popup-content-wrapper,.leaflet-popup-content
{
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
   border-radius: 0 !important;
}

.search__map__list{
  display: flex;
  flex-wrap: wrap;
}


.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom{

}