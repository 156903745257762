

@media (max-width:500px) {

    .for-mobile-footer {
        margin-left: 15px;
    }

 

}
/* .contactus-footer{
    font-weight: bolder;
    color: black;
} */

@media (max-width:760px) {

    .for-mobile-footer {
        margin-left: 15px;
        
    }
   


}
