.name{
    background-color: white !important;
    border: 0.1rem solid #dedede !important;
}
.email{
    background-color: white !important;
    border: 0.1rem solid #dedede !important;
}
.password{
    background-color: white !important;
    border: 0.1rem solid #dedede !important;
}
.c-password{
    background-color: white !important;
    border: 0.1rem solid #dedede !important;
}