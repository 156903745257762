.nav-btn {
    margin-bottom: 0rem !important;
}


.formobile-show{
    display: none;
}

@media (max-width:500px) {

    .fornone {
        display: none;
    }

    .image-mobile {
      margin-left: 200px;
    }
    .formobile-show{
        display: block;
    }

}

@media (max-width:760px) {

    .fornone {
        display: none;
    }
    .formobile-show{
        display:inline-block;
    }


}

