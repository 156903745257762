.signin{
    display: flex;
    justify-content: flex-end;
}
.forget-pass{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}
.name{
    background-color: white;
    border: 0.1rem solid #dedede !important;
}