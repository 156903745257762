
@media (max-width:500px) {

    .blog-for-mobile {
        margin-top: 100px;
    }



}

@media (max-width:700px) {

    .blog-for-mobile {
        
        margin-top: 100px;
    }



}
