.profile-info {
    display: flex;
    justify-content: center;
    width: 80%;
    margin: auto;
    border-left: 0.6rem solid #41878f;
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    flex-direction: column;
    box-shadow: 0rem 0rem 2rem #848484 !important;
}

.order-id {
    display: flex;
    flex-direction: inherit;
    justify-content: space-between;
    padding: 0rem 4rem !important;
}

.desc {
    padding-left: 0 !important;
}

.deliver-order {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
}

.deliver-lable {
    font-size: 1.3rem !important;
    font-weight: 500 !important;
}

.order-action {
    display: flex !important;
    justify-content: center;
}

.view-detail {
    position: relative;
    top: 2rem !important;
}

.btnEditStyle {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.btnEditStyle:hover {
    box-shadow: rgba(255, 0, 0, 0.25) 0px 0px 0px, rgba(255, 0, 0, 0.12) 0px -12px 30px, rgba(255, 0, 0, 0.12) 0px 4px 6px, rgba(255, 0, 0, 0.17) 0px 12px 13px, rgba(255, 0, 0, 0.09) 0px -3px 5px;
}


.overlay {

    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
}

.buttona {
    position: relative;
    position: relative;
    bottom: 170px;
    left: 0px;
    width: 200px;
    text-align: center;
    opacity: 0;
    transition: opacity .35s ease;
}

.buttona a {
    width: 100px;
    padding: 10px 40px;
    text-align: center;
    color: red;
    border: solid 2px red;

    z-index: 1;
}


.buttona a:hover{
    background-color: #41878f;
     color: white;
     border-color: #41878f;
}

.con-image:hover .buttona {
    opacity: 1;
}



.btnn {
    height: 50px;
    line-height: 50px;
    padding: 0 35px;
    overflow: hidden;
    position: relative;
    border: 0;
    transition: all 0.5s ease;
    font-size: 15px;
    display: inline-block;
    transform: perspective(1px) translateZ(0);
    border-radius: 0;
}

.deletehover a{
    color: red !important;
}
.deletehover a:hover{
    color: white !important;
}
