 .css-1s2u09g-control{
    background-color: white !important;
    /* height: 7vh !important; */
    /* width: 100% !important; */
}
/*
.css-tlfecz-indicatorContainer{
    background-color: white !important;
    height: 7vh !important;
    width: 100% !important;
} */
.states{
    background-color: white !important;
    height: 7vh !important;
    width: 100% !important;
}
.profile-info{
    display: flex;
    justify-content: center;
    width: 80%;
    margin: auto;
    border-left: 0.6rem solid #41878f;
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
}
.mySelect__value-container{
    height: '100%';
   
   }
.mySelect__control{
    background-color: white;
}
